import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import  { getRequest } from './../utils.js';
import Icon from './../components/Icon.js';
import Profile from './../components/Profile.js';
import Anon from './../public/anon.png';

import styled  from 'styled-components'

const Nav = styled.div`
display: flex;
flex-direction: column;
align-items: center;
height: 100vh;
min-height: 100vh;
max-height: 100vh;
min-width: 72px;
max-width: 72px;
z-index: 9;
background-color: var(--bg);
border-right: 2px solid var(--light-grey);
justify-content: space-between;
padding: 16px;
box-sizing: content-box;

.feedback{
  background-color: var(--grey);
  border-radius: 16px;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  margin-bottom: 32px;
  opacity: 0.8;
  transition-duration: 0.3s;
  &:hover{
    opacity: 1;
    img{
        filter: invert(40%) sepia(100%) saturate(250%) hue-rotate(45deg);
    }
  }
  img{
    transition-duration: 0.3s;
    margin: 8px;
    padding: 0;
  }

}
.profile {
  cursor: pointer;
  flex-direction:column;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  p{
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 12pt;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  img{
    border: 1px solid transparent;
    border-radius: 50%;
    width: 45px;
    transition-duration: 0.3s;
    height: 45px;
    object-fit: cover;
  }

  &:hover{
    img{
      border-color: var(--teal)
    }
  }
}
> div a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 16px;
  padding: 20px 16px;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  min-width: 100%;
  transition-duration: 0.3s;
  text-decoration: none;
  color: var(--white);
  border: 1px solid transparent;
  opacity: 0.5;
  font-size: 14px;
  &.active{
    background-color: var(--grey);
    color: var(--white);
    opacity: 1;
    .icon{
      filter: invert(40%) sepia(100%) saturate(250%) hue-rotate(45deg);
    }
  }

  &:hover{
    background-color: var(--grey);
    transition-duration: 0.3s;
    img{
      fill: var(--hilight);
    }
  }

  img{
    margin: 8px;
    margin-top: 0px;
    margin-bottom: 12px;

  }
}
`;
const StyledLink = styled.a`
  text-align: center;
  font-size: 12px !important;
  padding: 10px !important;
`;
const NavComp = () => {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' })
  const [features, setFeatures] = useState([])
  const [profileOpen, setProfileOpen] = useState(false)

  useEffect(() => {
    const getUser = async () => {
      const data = await getRequest('/user')
      if(data && !data.admin && window.location.href.includes('/admin')) {
        window.location.href = '/'
      }
      setUser(data)
      window.user = data
      if(data.features){
        setFeatures(data.features.filter(f => f.enabled).map(f => f.name))
      }
    }
    getUser()
  }, [])

  useEffect(() => {
    if(!user) return
    window.Canny('identify', {
      appID: '666098359f96f45657384c61',
      user: {
        email: user.email,
        name: user.name,
        id: user.id,
        avatarURL: user.picture,
      },
    });
  }, [user])


  const handleProfile = async () => {
    const data = await getRequest('/user')
    setUser(data)
    window.reloadUser()
    setProfileOpen(false)
  }

  const cancelProfile = async () => { setProfileOpen(false) }
  window.openEditUserModal = () => { setProfileOpen(true) }

  return (
    <Nav>
      { profileOpen && <Profile cancelProfile={cancelProfile} handleProfile={handleProfile}></Profile> }
      <div>
      <NavLink to="/" className={({isActive}) => (isActive ? "active" : 'none')}>
        <Icon name="learn" size={24} /> Learn
      </NavLink>

      <NavLink to="/library" className={({isActive}) => (isActive ? "active" : 'none')}>
        <Icon name="discover" size={24} /> Discover
      </NavLink>

      <NavLink to="/coaching" className={({isActive}) => (isActive ? "active" : 'none')}>
        <Icon name="home" size={24} /> Coach
      </NavLink>

      </div>

      <div>
        <div className="profile" onClick={() => setProfileOpen(true)}>
          <img src={user ? user.picture : Anon } alt="profile"/>
          <p style={{textAlign: 'center'}}>{user && user.name ? user.name.split(' ')[0].split('@')[0].trim() : ''}</p>
        </div>

        <a className="feedback" target="_blank" data-canny-link href="https://prequel.canny.io/prequel-hq-feedback-bug-reporting">
          <Icon name="learn" size={12} />
          Feedback
        </a>

        <StyledLink target="_blank" data-canny-link href="https://www.joinprequel.com/info/privacy">
          Privacy Policy
        </StyledLink>

      </div>
    </Nav>
  );
};

export default NavComp;
